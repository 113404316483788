import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import queryString from 'query-string'
import { WindowLocation } from '@reach/router'

import { Button } from '@paljs/ui/Button'
import { InputGroup } from '@paljs/ui/Input'
import Alert from '@paljs/ui/Alert'

import Auth, { Group } from '../../components/Auth'
import Loading from '../../components/Loading'

import useAuth from '../../util/useAuth'

import * as api from '../../util/api'

interface UserData {
  code: string
  password: string
  passwordConfirmation: string
}
type ResetProps = { location: WindowLocation }

const ResetPassword: React.FC<ResetProps> = ({ location }) => {
  const { code } = queryString.parse(location.search)
  const { getUser } = useAuth()

  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getUser()
      .then(() => navigate('/'))
      .catch(() => {
        if (!code) navigate('/auth')
        else setLoading(false)
      })
  }, [])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setError('')

    const data: UserData = {
      code: code.toString(),
      password: event.target['password'].value,
      passwordConfirmation: event.target['passwordConfirmation'].value,
    }

    try {
      await api.reset(data)
      navigate('/auth')
    } catch (e) {
      const error = api.getStrapiError(e)
      setError(error)
    }
  }

  if (loading) return <Loading isAuth />
  return (
    <Auth title='Reset Password' subTitle='Please insert the recovery code you received on your email account and then insert your new password.'>
      <form method='post' onSubmit={handleSubmit}>
        <InputGroup fullWidth>
          <input type='password' name='password' placeholder='New Password' />
        </InputGroup>
        <InputGroup fullWidth>
          <input type='password' name='passwordConfirmation' placeholder='Password Confirmation' />
        </InputGroup>
        <Button status='Success' type='submit' shape='SemiRound' fullWidth>
          Reset Password
        </Button>
        {!!error && (
          <Alert closable status='Danger' onClose={() => setError('')}>
            {error}
          </Alert>
        )}
      </form>
      <Group>
        <Link to='/auth/login'>Back to Log In</Link>
      </Group>
    </Auth>
  )
}
export default ResetPassword
